import { useEffect, useState } from 'react'
import { onAuthStateChanged, User } from 'firebase/auth'
import { authService, dbService } from '../FBase'
import { doc, getDoc } from 'firebase/firestore'

const useAuth = () => {
  const [user, setUser] = useState<User | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authService, async (user) => {
      // 로그인 타입
      const socialLoginType = user?.uid.split(':')[0]
      const userEmail: string = user?.email || ''
      // 이메일이 없으면 로그인 불가
      if (!userEmail) {
        setIsLoading(false)
        return
      }
      // 카카오, 네이버 로그인일 경우
      if (socialLoginType === 'kakao' || socialLoginType === 'naver') {
        const userRef = await doc(dbService, 'User', userEmail)
        const userSnap = await getDoc(userRef)
        if (userSnap.exists()) {
          const userData = userSnap.data()
          // userData에서 displayName 가져오기
          const preprocessedUserData: any = {
            ...user,
            displayName: userData?.displayName,
          }
          setUser(preprocessedUserData)
          setIsLoading(false)
        }
      } else {
        setUser(user)
        setIsLoading(false)
      }
    })

    return () => unsubscribe()
  }, [])

  return { user, isLoading }
}

export default useAuth

// setSocialLoginType(uid.split(':')[0])
