'use client'

import {
  Box,
  ButtonGroup,
  Card,
  CardHeader,
  Center,
  Flex,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react'

import Auth from '../socialLogin/page'
import useAuth from '../../../lib/useUser'

const Login = () => {
  const user = useAuth()

  return (
    <Box as={Flex}>
      <Flex
        mx={'auto'}
        my={20}
        justify={{ base: 'center', md: 'flex-start' }}>
        <Center>
          <Card
            as={Flex}
            rounded={'2xl'}
            minW="400px"
            variant={'unstyled'}
            p={'2rem'}>
            <CardHeader
              as={Stack}
              alignItems={{ base: 'center', md: 'flex-start' }}
              gap={'8px'}
              mb={5}>
              <Heading fontSize={{ base: '7.5vmin', sm: '2rem' }}>
                로그인
              </Heading>
              <Text
                fontSize={{ base: '4.5vmin', sm: '1rem' }}
                // fontWeight={700}
                fontFamily={'SuitsBoldFont'}
                color="#525252">
                안녕하세요. 여름숲 관리자 모드입니다.
              </Text>
            </CardHeader>
            <ButtonGroup
              as={Stack}
              w={'100%'}>
              <Auth />
            </ButtonGroup>
          </Card>
        </Center>
      </Flex>
    </Box>
  )
}

export default Login
